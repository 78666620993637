export const LeaderBoardApi = [
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },

];
export const LeaderBoardApi2 = [
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
     {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
     {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
         title:"Abstract Texture",
          maxPrice:" crazy4 ",
          maxPrice2:".48 ETH",
          daysLeft:"23" 
    }
];


export const LeaderBoardApi3 = [
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    },
    {
        name:"Dungeons and Dragons",
        volume:"($200)",
        owners:"1k",
        items:"10k"
    }
];


export const LeaderBoardApi4 = [
    {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    },
    {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    },
    {
        img :"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    }, {
        img :"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    }, {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    }, {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    }, {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    }, {
        img:"https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        name:"Dussy",
        volume:"($200)",
        itemsbought:"10k"
    },
];



export const LeaderBoardApi5 = [
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
    {
        name:"Dussy",
        volume:"($200)",
        itemssold:"10k"
    },
];


export const LeaderBoardApi6 = [
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    {
        title:"Abstract Illusion",
         image:"https://earncashto.com/wp-content/uploads/2021/06/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png",
          no_of_items:"2",
    },
    
];



export const AbstractApi = [
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
        price : "0.32 ETH",
        title:"Abstract Texture",
          maxPrice:" Highest bid: ",
          maxPrice2:"0.48 ETH",
          daysLeft:"34" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
        price : "0.32 ETH",
        title:"3D Art",
          maxPrice:"Price:",
          maxPrice2:"0.13 ETH",
          daysLeft:"34" 
    },
    {
        image : "https://th.bing.com/th/id/R.86a13194299b9242b35c43409764d833?rik=EUjhD0IqVmGMGQ&riu=http%3a%2f%2fwww.baltana.com%2ffiles%2fwallpapers-8%2fGlitter-Bokeh-Background-Wallpaper-24846.jpg&ehk=a4S82qTwktCWbbHtyq1%2bPsAr68%2fM2HrNOVsDQLVc0Cw%3d&risl=1&pid=ImgRaw&r=0",
        price : "0.32 ETH",
        title:"3D Art",
          maxPrice:"Price:",
          maxPrice2:"0.13 ETH",
          daysLeft:"34" 
    },
];

export const Pending = [
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist1111",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
];

export const Accepted = [
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist222",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
  
];
export const Rejected = [
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist333",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
    {
        Image : "https://th.bing.com/th/id/R.e1189efa9cd3aee29c0e1f7dbed689bf?rik=YRidGY7NPM2n3A&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2f7ca%2fpeo%2f7capeoboi.png&ehk=MwVRL6ome8bAroWEn5dLYQgaXLxrafgcwcIQX7N48CM%3d&risl=&pid=ImgRaw&r=0",
        Heading : "@LazyArtist",
        SubHead1 : "34 ETH",
        SubHead2 : "($200)"
    },
];