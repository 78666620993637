export const Myitem_API = [
  {
    id: 1,
    image:
      "https://semidotinfotech.com/blog/wp-content/uploads/2021/05/A-Guide-to-Develop-NFT-Marketplace.jpg",
    title: "Abstratct Texture",
  },
];

export const MyItemCollectionApi = [
  {
    id: 1,
    image:
      "https://semidotinfotech.com/blog/wp-content/uploads/2021/05/A-Guide-to-Develop-NFT-Marketplace.jpg",
    title: "Abstratct Illusions",
    bottomText: "Total items",
    number: "2",
  },
];
